<template>
  <v-container>
    <v-expansion-panels v-model="activePanel">

      <v-expansion-panel v-for="form in tableData" :key="form.id">
        <v-expansion-panel-header>
          <v-row class="mr-10">
            <v-col class="my-auto">
              <h2>
                {{ form.title }}
              </h2>
            </v-col>
            <v-col align="right">
              <v-btn @click="downloadAnswers(form.id, form.title)" download color="primary">
                Ladda ned
              </v-btn>
              <v-btn class="mx-1" color="error" @click="deleteAnswers(form.id, form.title)">Rensa svar</v-btn>
            </v-col>
          </v-row>

        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table item-value="Namn" :headers="form.headers" :items="form.rows">
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </v-container>
</template>

<script>
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

export default {
  data: () => ({
    forms: undefined,
    answers: undefined,
    tableData: [], // [{form_id, [headers], [{columns}]}]
    activePanel: []
  }),
  mounted: function () {
    axios.get('/api/forms/fetchAllAnswers', getAuthHeader())
      .then((result) => {
        this.forms = result.data.forms
        this.answers = result.data.answers

        for (const form of this.forms) {
          const formId = form.ID
          const formName = form.name
          const answers = this.answers[formId]
          const keys = []
          const tableData = { headers: [], rows: [], title: formName, id: formId }
          let index = 0
          for (const field of form.jsonData.components) {
            tableData.headers.push({ text: field.title, value: field.title })
            keys.push({ index: index, title: field.title, type: field.type, options: field.options })
            index++
          }
          tableData.headers.push({ text: 'Tid', value: 'Tid' })

          for (const i in answers) {
            const obj = {}
            for (const field of keys) {
              if (field.type === 'checkbox') {
                const data = []
                for (let j = 0; j < field.options.length; j++) {
                  if (answers[i].data[field.index][j]) {
                    data.push(field.options[j])
                  }
                }
                obj[field.title] = data
              } else {
                obj[field.title] = answers[i].data[field.index]
              }
            }
            tableData.rows.push({ ...obj, Tid: answers[i].timestamp })
          }

          this.tableData.push(tableData)
        }
      })
  },
  methods: {
    downloadAnswers (id, name) {
      axios.get('/api/forms/exportAnswers/' + id, getAuthHeader())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fURL = document.createElement('a')

          fURL.href = fileURL
          fURL.setAttribute('download', name + '.csv')
          document.body.appendChild(fURL)

          fURL.click()
        }).catch((response) => {
          alert("Kunde inte hämta svaren :'(")
        })
    },
    deleteAnswers (id, name) {
      if (confirm(`Är du säker på att du vill radera svaren till formuläret ${name}?`)) {
        axios.get('/api/forms/deleteAnswers/' + id, getAuthHeader())
          .then((response) => {
            console.log(response)
          }).catch((response) => {
            alert("Misslyckades med att rensa svaren :'(")
          })
      }
    }
  }
}
</script>
